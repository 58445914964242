import { getFrameworkVars } from '@sparky/framework';
import navExtension from './navigation';
import PDFReportsExtensions from './pdf-report';
import redirects from './redirects';
import routesExtension from './routes';
import stateLoaders from './stateLoaders';
// export default [
//   PDFReportsExtensions,
//   leftNavExtension,
//   routesExtension,
//   redirects,
// ];
export var extensions = function (_a) {
    var vars = _a.vars;
    console.log('Registering Sparky extensions...');
    var envName = (vars !== null && vars !== void 0 ? vars : {}).env_name || process.env.ENV_NAME;
    var pdf_mode = getFrameworkVars().pdf_mode;
    var logStyle = 'color:#fa582d; background:#141414; font-size:0.85rem; padding:0.5rem; margin: 0.15rem auto; font-family: Rockwell; border: 2px solid #fa582d; border-radius: 4px;font-weight: bold; text-shadow: 1px 1px 1px #141414;';
    console.log("%c adem ui env: ".concat(envName, " \uD83C\uDF0F"), logStyle);
    // eslint-disable-next-line no-undef
    console.log("%c adem ui version: ".concat(process.env.APP_VERSION, " \uD83D\uDCE6"), logStyle);
    console.log("%c is pdf_mode: ".concat(!!pdf_mode, " \uD83D\uDCE6"), logStyle);
    if (envName === 'staging') {
        // eslint-disable-next-line no-undef
        console.info('%c release changelog 🚀: ', logStyle, "https://prisma-access.docs.pan.run/sase-adem-group/dem-ui/changelog.html");
    }
    return [
        PDFReportsExtensions,
        navExtension,
        routesExtension,
        redirects,
        stateLoaders,
        // Add any additional extensions here
    ];
};
