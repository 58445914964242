import { SPARKY_NAV3_REDIRECTS_EXTPOINT, } from '@sparky/framework/extpoints';
// temp - use below to generate the redirects,
// comment out here because we need to avoid importing saseViews/saseView.routes as the loader has hard limit 300000 bytes
// type ObjectKeysAsList<T> = (keyof T)[];
// const redirectKeys: ObjectKeysAsList<typeof legacySaseViewRoutes> = [
//   'NewAppTest',
//   'RumAppDomains',
//   'RumDashboard',
//   'RumAppDetails',
//   'ZoomRedirect',
//   'MsTeamsRedirect',
//   'AppDetails',
//   'AppDetailsExperience',
//   'AppDetailsActivity',
//   'UserDetails',
//   'UserDetailsThreats',
//   'UserDetailsExperience',
//   'UserDetailsConnectivity',
//   'UserSelfServe',
//   'Locations',
//   'LocationDetails',
//   'LocationDetailsConnectivity',
//   'LocationDetailsExperience',
//   'NgfwSites',
//   'Sites',
//   'NgfwSiteDetails',
//   'SiteDetails',
// ];
// console.log(
//   redirectKeys.map((key) => ({
//     id: `sase.dem_app.nav.redirects.${key}`,
//     config: {
//       path: legacySaseViewRoutes[key],
//       //@ts-expect-error fix type
//       redirectTo: nav3Paths[key],
//       preserveSearch: true,
//     },
//   }))
// );
export default {
    point: SPARKY_NAV3_REDIRECTS_EXTPOINT,
    configs: [
        {
            id: 'sase.dem_app.nav.redirects.NewAppTest',
            config: {
                path: '/dem/dem-rum-views/new-test',
                redirectTo: '/insights/operational/application-experience/domain-test-form',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumAppDomains',
            config: {
                path: '/dem/dem-rum-views/application-experience/app-domains',
                redirectTo: '/insights/operational/application-experience/app-domains',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumDashboard',
            config: {
                path: '/dem/dem-rum-views/application-experience/your-org',
                redirectTo: '/insights/operational/application-experience/your-org',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.RumAppDetails',
            config: {
                path: '/dem/dem-rum-views/app-detail-dashboard',
                redirectTo: '/insights/operational/application-experience/domain-details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.ZoomRedirect',
            config: {
                path: '/insights/activity_insights_app/apps/dem-zoom-redirect',
                redirectTo: '/insights/operational/application-experience/dem-zoom-redirect',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.MsTeamsRedirect',
            config: {
                path: '/insights/activity_insights_app/apps/dem-msteams-redirect',
                redirectTo: '/insights/operational/application-experience/dem-msteams-redirect',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetails',
            config: {
                path: '/insights/activity_insights_app/apps/details',
                redirectTo: '/insights/activity_insights/apps/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetailsExperience',
            config: {
                path: '/insights/activity_insights_app/apps/details/experience',
                redirectTo: '/insights/activity_insights/apps/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.AppDetailsActivity',
            config: {
                path: '/insights/activity_insights_app/apps/details/activity',
                redirectTo: '/insights/activity_insights/apps/details/activity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetails',
            config: {
                path: '/insights/activity_insights_user/users/details',
                redirectTo: '/insights/activity_insights/users/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsThreats',
            config: {
                path: '/insights/activity_insights_user/users/details/threats',
                redirectTo: '/insights/activity_insights/users/details/threats',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsExperience',
            config: {
                path: '/insights/activity_insights_user/users/details/experience',
                redirectTo: '/insights/activity_insights/users/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserDetailsConnectivity',
            config: {
                path: '/insights/activity_insights_user/users/details/connectivity',
                redirectTo: '/insights/activity_insights/users/details/connectivity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.UserSelfServe',
            config: {
                path: '/insights/activity_insights_user/users/self-serve',
                redirectTo: '/insights/activity_insights/users/self-serve',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.Locations',
            config: {
                path: '/monitor/palocations',
                redirectTo: '/insights/operational/prisma-access-locations',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetails',
            config: {
                path: '/monitor/palocations/details',
                redirectTo: '/insights/operational/prisma-access-locations/details',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetailsConnectivity',
            config: {
                path: '/monitor/palocations/details/connectivity',
                redirectTo: '/insights/operational/prisma-access-locations/details/connectivity',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.LocationDetailsExperience',
            config: {
                path: '/monitor/palocations/details/experience',
                redirectTo: '/insights/operational/prisma-access-locations/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.NgfwSites',
            config: {
                path: '/monitor/branch-sites/ngfwSites',
                redirectTo: '/insights/operational/sase-branch-sites/ngfwSites',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.Sites',
            config: {
                path: '/monitor/branch-sites/sdwan',
                redirectTo: '/insights/operational/sase-branch-sites/sdwan',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.NgfwSiteDetails',
            config: {
                path: '/monitor/branch-sites/ngfwSites/details/experience',
                redirectTo: '/insights/operational/sase-branch-sites/ngfwSites/details/experience',
                preserveSearch: true,
            },
        },
        {
            id: 'sase.dem_app.nav.redirects.SiteDetails',
            config: {
                path: '/monitor/branch-sites/:id/site-summary-v2/experience',
                redirectTo: '/insights/operational/sdwan/sase-branch-sites/:id/site-summary-v2/experience',
                preserveSearch: true,
            },
        },
    ],
};
