import { getAuthState, getFrameworkVars, getState } from '@sparky/framework';
import { SPARKY_NAV3_LEFT_NAV_EXTPOINT, } from '@sparky/framework/extpoints';
import { checkAdemMicroAppStatus } from '../computeRolePermission';
import { nav3Paths } from './paths';
import { isFeatureEnabled } from './utils';
export default {
    // declare ext for left nav tree
    point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    configs: [
        {
            id: 'sase.dem_app.nav.dem',
            config: {
                stateLoaders: ['auth', 'adem_main'],
                isAllowed: function () { return true; },
                navigation: function () {
                    /** ?????
                     * TODO: uncomment the below lines after April 10th.
                     * Since the GA date for Activity Insights and Netsec Visibility is on April 10th.
                     * So for now we still need the Monitor - Users page to always be present
                     */
                    var private_site_origin = getFrameworkVars().private_site_origin;
                    var auth = getAuthState();
                    var isPrivateApp = Boolean(private_site_origin);
                    var isAdemMicroAppEnabled = checkAdemMicroAppStatus(auth);
                    if (!(isAdemMicroAppEnabled || isPrivateApp)) {
                        return null;
                    }
                    return [
                        {
                            key: 'insights',
                            path: '/insights',
                            title: 'Insights',
                            children: [
                                {
                                    key: 'operational-insights',
                                    path: '/insights/operational',
                                    title: 'Operational',
                                    children: [
                                        {
                                            key: 'application-experience',
                                            title: 'Application Experience',
                                            path: nav3Paths.AppDomainExp,
                                            avail: function () {
                                                return isFeatureEnabled('panw_sase:application_experience', true);
                                            },
                                        },
                                        {
                                            key: 'prisma-sase',
                                            title: 'Prisma SASE',
                                            path: '/insights/operational/prisma-sase',
                                            expandable: true,
                                            children: [
                                                {
                                                    key: 'dem-prisma-access-locations',
                                                    title: 'Prisma Access Locations',
                                                    path: nav3Paths.Locations,
                                                    avail: function () {
                                                        return isFeatureEnabled('panw_sase:pa_location', true);
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            key: 'settings',
                            path: '/settings',
                            children: [
                                {
                                    key: 'dem-settings',
                                    title: 'Access Experience Management',
                                    path: nav3Paths.Setting,
                                    avail: function () {
                                        var _a, _b, _c;
                                        return isFeatureEnabled('panw_sase:settings_system_application', true) ||
                                            ((_c = (_b = (_a = getState()) === null || _a === void 0 ? void 0 : _a.adem_main) === null || _b === void 0 ? void 0 : _b.setUpResponse) === null || _c === void 0 ? void 0 : _c.isSettingsVisible);
                                    },
                                },
                            ],
                        },
                    ];
                },
            },
        },
    ],
};
