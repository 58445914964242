import { getState } from '@sparky/framework';
export var isFeatureEnabled = function (feature, defaultValue) {
    var _a, _b, _c, _d;
    if (defaultValue === void 0) { defaultValue = false; }
    var cosmosFeatures = ((_b = (_a = getState()) === null || _a === void 0 ? void 0 : _a.saseIAMainState) !== null && _b !== void 0 ? _b : {}).cosmosFeatures;
    if (!(cosmosFeatures === null || cosmosFeatures === void 0 ? void 0 : cosmosFeatures.length)) {
        return Boolean(defaultValue);
    }
    return (_d = (_c = cosmosFeatures
        .find(function (data) { return data.feature === feature; })) === null || _c === void 0 ? void 0 : _c.permission) === null || _d === void 0 ? void 0 : _d.includes('read');
};
