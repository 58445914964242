var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getFeatureFlag } from '@sparky/framework';
import { addRoutesPrefixForAIOPSFreeIfNeeded } from '../saseViews/utils/addRoutesPrefixForAIOPSFreeIfNeeded';
// nav3
var Nav3MonitorApplicationsRoutesInsights = {
    Applications: '/insights/activity_insights/apps/',
    NewAppTest: '/insights/operational/application-experience/domain-test-form',
    EditAppTest: '/insights/operational/application-experience/domain-test-form',
    ManageAppTests: '/insights/activity_insights/apps/manage-application',
    AppDetails: '/insights/activity_insights/apps/details',
    AppDetailsActivity: '/insights/activity_insights/apps/details/activity',
    AppDetailsExperience: '/insights/activity_insights/apps/details/experience',
    ManageNetworkServices: '/insights/activity_insights/apps/manage-network-services',
    ZoomRedirect: '/insights/operational/application-experience/dem-zoom-redirect',
    MsTeamsRedirect: '/insights/operational/application-experience/dem-msteams-redirect',
    RumAppDetails: '/insights/operational/application-experience/domain-details',
    RumAppTestForm: '/dem/dem-rum-views/new-test',
    RumAppDomains: '/insights/operational/application-experience/app-domains',
    RumDashboard: '/insights/operational/application-experience/your-org',
};
var Nav3MonitorUsersRoutesInsights = {
    Users: '/insights/activity_insights/users',
    UserDetails: '/insights/activity_insights/users/details',
    UserDetailsThreats: '/insights/activity_insights/users/details/threats',
    UserDetailsExperience: '/insights/activity_insights/users/details/experience',
    UserDetailsConnectivity: '/insights/activity_insights/users/details/connectivity',
    UserSelfServe: '/insights/activity_insights/users/self-serve', // how we handle this?
};
export var Nav3MonitorApplicationsRoutes = addRoutesPrefixForAIOPSFreeIfNeeded(Nav3MonitorApplicationsRoutesInsights);
export var Nav3MonitorUsersRoutes = addRoutesPrefixForAIOPSFreeIfNeeded(Nav3MonitorUsersRoutesInsights);
export var Nav3MonitorPALocationsRoutes = {
    Locations: '/insights/operational/prisma-access-locations',
    LocationDetails: '/insights/operational/prisma-access-locations/details',
    LocationDetailsConnectivity: '/insights/operational/prisma-access-locations/details/connectivity',
    LocationDetailsExperience: '/insights/operational/prisma-access-locations/details/experience',
};
// todo: Ankur to check, can be removed
export var Nav3MonitorSitesRoutes = {
    Sites: '/insights/operational/sase-branch-sites/sdwan',
    SitesSDWAN: '/insights/operational/sase-branch-sites/sdwan',
    SiteDetails: '/insights/operational/sdwan/sase-branch-sites/:id/site-summary-v2/experience',
};
export var Nav3NgfwMonitorSitesRoutes = {
    NgfwSites: '/insights/operational/sase-branch-sites/ngfwSites',
    NgfwSiteDetails: '/insights/operational/sase-branch-sites/ngfwSites/details/experience',
};
export var Nav3SettingsRoutes = {
    Setting: '/settings/dem-settings',
};
export var nav3Paths = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, Nav3MonitorApplicationsRoutes), Nav3MonitorUsersRoutes), Nav3MonitorPALocationsRoutes), Nav3SettingsRoutes), Nav3MonitorSitesRoutes), Nav3NgfwMonitorSitesRoutes), { LogViewer: '/logviewer', AppAccelerationSettings: '/workflows/app-acceleration', InsightsUrls: '/insights/activity_insights/urls', InsightsDomains: '/insights/activity_insights/domains', InsightsThreats: '/insights/activity_insights/threats', InsightsRules: '/insights/activity_insights/rules', InsightsProjects: '/insights/activity_insights/projects', PabManagementEvents: '/manage/pab-management/events', Plugins: '/manage/saas-security/posture-security/third-party-plugins', 
    //new added
    AppDomainExp: '/insights/operational/application-experience' });
export var shouldUseNavV3 = function (routes, nav3routes) {
    console.log('nav3 flag', getFeatureFlag('sparky.framework.use_scm_nav_v3'));
    return getFeatureFlag('sparky.framework.use_scm_nav_v3')
        ? nav3routes
        : routes;
};
